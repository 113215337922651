import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
// import Paper from '@material-ui/core/Paper';
// import TextField from '@material-ui/core/TextField';
// import InputLabel from '@material-ui/core/InputLabel';
// import Button from '@material-ui/core/Button';
// import AccountBoxIcon from '@material-ui/icons/AccountBox';
// import SearchIcon from '@material-ui/icons/Search';
//import QsiseExecuteStore from './stores/QsiseExecuteStore';
import ConfigFormStore from '../configuration/stores/ConfigFormStore';
import DetailsNavigator from '../../../components/details-navigator/DetailsNavigator';
import ConfigExecuteStore from '../configuration/stores/ConfigExecuteStore';
import { CircularProgress, Grid } from '@material-ui/core';
import Loader from '../../../components/loader';
import Preview from '../../../components/preview/Preview';

const styleMaterial = () => {
  return {
    button: {
      width: '200px',
      backgroundColor: 'rgb(255, 255, 255)',
      borderRadius: '5px',
      color: '#3f51b5',
      fontSize: '14px !important',
      height: '40px'
    },
    underline: {
      '&:before': {
        borderBottom: '1px solid rgb(252, 153, 0)'
      },
      '&:after': {
        borderBottom: '1px solid rgb(252, 153, 0)'
      },
      '&:hover:before': {
        borderBottom: '1px solid rgb(252, 153, 0) !important'
      }
    },
    inputSelect: {
      fontSize: '14px',
      padding: '5px 24px !important'
    },
    input: {
      '&::placeholder': {
        fontSize: '14px',
        color: 'rgb(252, 153, 0)',
        opacity: '1 !important'
      }
    },
    input2: {
      '&::placeholder': {
        fontSize: '14px',
        color: 'rgb(252, 153, 0)',
        opacity: '1 !important',
        fontWeight: 650
      }
    },
    root: {
      color: 'rgb(252, 153, 0) !important',
      fontSize: 20
    },
    select: {
      padding: '5px 0 0 !important'
    },
    selectMenu: {
      fontSize: '20px',
      margin: 2
    },
    label: {
      color: 'rgb(252, 153, 0)'
    },
    icon: {
      fill: 'rgb(252, 153, 0)'
    }
  };
};

const WindowTitle = styled.div`
  color: rgb(119, 119, 119);
  font-weight: bold;
  margin-left: 30px;
  box-sizing: border-box;
  font-size: 14px;
  padding-bottom: 10px;
  padding-top: 10px;

  @media (min-device-width: 360px) and (max-device-width: 900px) {
    padding-top: 20px;
    margin-left: 0px;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
  }
`;

const ThemedContainer = styled.div`
  & {
    .theme-primary {
      background-color: ${props => props.theme.primary && props.theme.primary.main} !important;
    }

    .theme-primary-variant {
      background-color: ${props => props.theme.primary && props.theme.primary.variant1} !important;
    }

    .theme-primary-icon {
      path {
        fill: ${props => props.theme.primary && props.theme.primary.main} !important;
      }
    }

    .theme-primary-icon-variant {
      path {
        fill: ${props => props.theme.primary && props.theme.primary.variant1} !important;
      }
    }

    .theme-primary-text {
      color: ${props => props.theme.primary && props.theme.primary.main} !important;
    }

    .theme-primary-text-variant {
      color: ${props => props.theme.primary && props.theme.primary.variant1} !important;
    }

    .theme-secondary {
      background-color: ${props => props.theme.secondary && props.theme.secondary.main} !important;
    }

    .theme-secondary-variant1 {
      background-color: ${props => props.theme.secondary && props.theme.secondary.variant1} !important;
    }

    .theme-secondary-variant2 {
      background-color: ${props => props.theme.secondary && props.theme.secondary.variant2} !important;
    }

    .theme-secondary-variant3 {
      background-color: ${props => props.theme.secondary && props.theme.secondary.variant3} !important;
    }

    .theme-secondary-variant4 {
      background-color: ${props => props.theme.secondary && props.theme.secondary.variant4} !important;
    }

    .theme-secondary-variant5 {
      background-color: ${props => props.theme.secondary && props.theme.secondary.variant5} !important;
    }

    .theme-secondary-icon {
      path {
        fill: ${props => props.theme.secondary && props.theme.secondary.main} !important;
      }
    }

    .theme-secondary-icon-variant1 {
      path {
        fill: ${props => props.theme.secondary && props.theme.secondary.variant1} !important; 
      }
    }

    .theme-secondary-icon-variant2 {
      path {
        fill: ${props => props.theme.secondary && props.theme.secondary.variant2} !important;
      }
    }

    .theme-secondary-icon-variant3 {
      path {
        fill: ${props => props.theme.secondary && props.theme.secondary.variant3} !important;
      }
    }

    .theme-secondary-icon-variant4 {
      path {
        fill: ${props => props.theme.secondary && props.theme.secondary.variant4} !important;
      }
    }

    .theme-secondary-icon-variant5 {
      path {
        fill: ${props => props.theme.secondary && props.theme.secondary.variant5} !important;
      }
    }

    .theme-secondary-text {
      color: ${props => props.theme.secondary && props.theme.secondary.main} !important;
    }

    .theme-secondary-text-variant1 {
      color: ${props => props.theme.secondary && props.theme.secondary.variant1} !important;
    }

    .theme-secondary-text-variant2 {
      color: ${props => props.theme.secondary && props.theme.secondary.variant2} !important;
    }

    .theme-secondary-text-variant3 {
      color: ${props => props.theme.secondary && props.theme.secondary.variant3} !important;
    }

    .theme-secondary-text-variant4 {
      color: ${props => props.theme.secondary && props.theme.secondary.variant4} !important;
    }

    .theme-secondary-text-variant5 {
      color: ${props => props.theme.secondary && props.theme.secondary.variant5} !important;
    }
  }
`;

@inject((injectables) => ({
  snackBarStore: injectables.snackBarStore,
  loaderStore: injectables.loaderStore,
  qSiseService: injectables.qSiseService,
  qSiseID: injectables.qSiseID,
  variables: injectables.variables,
  qSiseIndex: injectables.qSiseIndex,
  theme: injectables.theme,
  externalSource: injectables.externalSource,
  mode: injectables.mode,
  externalData: injectables.externalData
}))
@observer
class QsiseExecute extends Component {
  constructor(props) {
    super(props);
    this.configExecuteStore = new ConfigExecuteStore(
      this.props.qSiseService,
      this.props.loaderStore,
      this.props.snackBarStore
    );
    this.configFormStore = new ConfigFormStore(
      this.props.qSiseService,
      this.props.loaderStore,
      this.props.snackBarStore
    );
  }
  componentDidMount = async () => {
    if (this.props.qSiseID) {
      await this.configFormStore.getQsiseById(this.props.qSiseID);
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.variables !== prevProps.variables) {
      console.log('Different qSISE!');
      if (this.props.qSiseID) {
        await this.configFormStore.getQsiseById(this.props.qSiseID);
      }
    }
  }

  componentWillUnmount() {
    sessionStorage.removeItem('sharedData');
  }

  setVariable = (name, value) => {
    this.configFormStore.setVariable(name, value);
  };

  render() {
    const { qSise, fetching } = this.configFormStore;
    const { mode, externalSource, variables, qSiseIndex, theme, snackBarStore, externalData } = this.props;
    const selectedClient = toJS(qSise);
    const parsedVariables = variables;
    const tabIndex = qSiseIndex;
    if (selectedClient !== null) {
      selectedClient.variables = selectedClient.variables.map((variable) => {
        const v = variable;
        if (parsedVariables[v.value]) {
          v.defaultValue = parsedVariables[v.value];
        }

        return v;
      });
    }

    return (
      <div>
        <ThemedContainer theme={theme}>
          {mode === 'write' ? (
            <Preview
              source={externalSource.template}
              script={{
                global: '',
                local: externalSource.script
              }}
              globalScope={[]}
              globalData={externalSource.data ? externalSource.data : {}}
              localData={{}}
              changeTab={undefined}
              setVariable={this.setVariable}
              snackBarStore={snackBarStore}
              theme={theme}
            />
          ) : (
            selectedClient !== null ? (<DetailsNavigator
                items={selectedClient.areas}
                router={this.props.router}
                qSiseStore={this.configExecuteStore}
                variables={selectedClient.variables}
                qSiseId={this.props.qSiseID}
                snackBarStore={snackBarStore}
                setVariable={this.setVariable}
                tabIndex={tabIndex}
                theme={theme}
                externalData={externalData}
              />) : <Loader theme={theme} />
          )}
        </ThemedContainer>
      </div>
    );
  }
}

export default withStyles(styleMaterial)(QsiseExecute);
